import React from 'react';
import './App.css';
import Content from './components/Content';
import Header from './components/Header';

function App() {
  return (
    <div className="main_content">
      <Header />
      <Content />
    </div>
  );
}

export default App;
