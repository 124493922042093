import { ReactElement } from "react";

const ShareButton = (): ReactElement => {
  return (
    <div className="fb-share-button" data-href="https://tanarballagas.hu/" data-layout="button" data-size="small">
      <a
        target="_blank"
        href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Ftanarballagas.hu%2F&amp;src=sdkpreparse"
        className="fb-xfbml-parse-ignore"
        rel="noopener noreferrer"
      >
        Share
      </a>
    </div>
  )
}

export default ShareButton;