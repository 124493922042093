import flower from "../media/tanarballagas_04.png";
import { useEffect, useState } from "react";
import map from "../media/tanarballagas_utvonalterv.png";

export default function Content() {
  const [viewWidth, setViewWidth] = useState<number | null>(window.innerWidth);

  useEffect(() => {
    const getWidth = () => {
      setViewWidth(window.innerWidth);
    }
    window.addEventListener('resize', getWidth, false);

    return () => {
      window.removeEventListener('resize', getWidth, false);
    }
  }, [])

  const redFont = {
    color: '#C11414',
    fontWeight: '600',
    fontSize: '40px',
    marginBottom: '36px'
  }

  return ( 
    <>
      <div className="block_element" style={{paddingTop: '60px', paddingBottom: '40px'}}>
        <p className="highlight" style={viewWidth && viewWidth > 800 ? {} : {lineHeight: '1.5rem', padding: '0 2rem'}}>
          Az eseménnyel a pályaelhagyás problémáján keresztül a közoktatás egészének tarthatatlan állapotára kívánjuk felhívni a figyelmet.
        </p>
      </div>
      <div className="block_frame" style={{background: 'var(--blue)'}}>
        <div className="block_element grid_element">
          <div className="block_info">
            <h2>Időpont</h2>
            <p>2022. november 8.</p>
            <p>17.00-18.30</p>
          </div>
          <div className="block_info">
            <h2>Helyszín</h2>
            <p>Budapest, Deák F. tér</p>
            <p>- Szent István tér (Bazilika előtt)</p>
          </div>
        </div>
      </div>

      <div className="block_frame image_bg">
        <div className="block_element program_element">
          <h1>Program</h1>
          <div className="block_info">
            <p>16.45-17.00</p>
            <p>gyülekező a Deák téren</p>
          </div>
          <div className="block_info">
            <p>17.00-17.30</p>
            <p>ballagás (ballagási énekek)</p>
          </div>
          <div className="block_info">
            <p>17.30-17.40</p>
            <p>érkezés a Bazilika elé, köszöntések</p>
          </div>
          <div className="block_info">
            <p>17.40-18.00</p>
            <p>rövid beszédek, állásfoglalás felolvasása</p>
          </div>
        </div>
      </div>
      <div className="block_element" style={{ maxWidth: '764px'}}>
        <p className="text_type">A ballagtatók és támogatók beállhatnak a sorfalba a ballagás kijelölt útvonala mentén (lásd a térképen), majd az elhaladó ballagók után a menetbe; illetve a Szent István-bazilika előtt tudnak csatlakozni.</p>
        <p className="text_type">A ballagtatóktól egy szál virágot szívesen fogadnak a ballagó kollégák, átadás a Bazilika előtt.</p>
        <p className="text_type">Kérjük, hogy a ballagók ne tartsanak transzparenseket!</p>
        <p className="text_type">Pedagóguskollégáknak megjelenés lehetőleg a ballagáshoz illő öltözetben.</p>
        <p className="text_type">Kérjük, hogy a kollégák viseljenek ballagási tarisznyát!</p>
        <p className="text_type">Útvonaltérkép és további információk a <a href="https://fb.me/e/5klmarO2n" rel="noreferrer noopener" target="_blank">Facebookon</a> és a <a href="mailto:tanarballagas@gmail.com">tanarballagas@gmail.com</a> címen érhetők el.</p>
        <p className="text_type" style={{fontSize: '1rem'}}>A szervezők egyházi iskolák tanárai: Lakatos Máté, Melegh Béla, Mikesy András, Nagy Bence, Posta István, Szőke Máté</p>
      </div>
      <div className="block_element" style={{width: '100%'}}>
        <span style={{overflowY: 'hidden', width: '100%'}}>
          <img
            src={map}
            alt="Tanárballagás útvonalterv"
            style={{width: '100%', height: '100%', objectFit: 'cover'}}
          />
        </span>
      </div>
      <div className="block_element" style={{marginTop: '30px', marginBottom: '30px'}}>
        <img
          src={flower}
          alt="Asztali fektett virág"
          style={viewWidth && viewWidth > 800 ? {} : {width: '100%'}}
        />
      </div>
      <div className="block_frame" style={{background: 'black', color: 'red', paddingTop: '60px'}}>
        <div className="block_element highlight_element" style={{maxWidth: '1290px'}}>
          <p style={viewWidth && viewWidth > 800 ? redFont : {...redFont, fontSize: '1.25rem'}}>Nincsen családbarát politika felelős oktatáspolitika nélkül!</p>
          <p style={viewWidth && viewWidth > 800 ? {...redFont, fontSize: '60px'} : {...redFont, fontSize: '30px', lineHeight: '34px'}}>Nemzeti oktatási stratégiát!</p>
        </div>
      </div>
      <div className="block_frame" style={{background: 'var(--blue)'}}>
        <div className="block_element grid_element" style={{alignItems: 'center', padding: 0}}>
          <a href="mailto:tanarballagas@gmail.com" style={viewWidth && viewWidth > 800 ? {color: 'white', fontSize: '25px'} : {color: 'white', fontSize: '20px'}}>tanarballagas@gmail.com</a>
          <a href="/Allasfoglalas_az_oktatas_mai_helyzeterol.pdf"rel="noopener noreferrer" target="_blank" style={viewWidth && viewWidth > 800 ? {color: 'white', fontSize: '25px'} : {color: 'white', fontSize: '20px'}}>Állásfoglalás az oktatás helyzetéről (2020)</a>
          <a href="/Tanarballagas_meghivo.pdf" rel="noopener noreferrer" target="_blank" style={viewWidth && viewWidth > 800 ? {color: 'white', fontSize: '25px', gridColumn: '1 / -1', marginTop: '2rem'} : {color: 'white', fontSize: '20px'}}>meghívó letöltése</a>
        </div>
      </div>
    </>
  )
}