import { ReactElement, useEffect, useState } from "react"
import header from "../media/tanarballagas_01.png";
import mobile from "../media/tanarballagas_02.png";
import left from "../media/tanarballagas_05.png";
import right from "../media/tanarballagas_06.png";
import ShareButton from "../ShareButton";
import Svg from "./Svg";

const Header = (): ReactElement => {
  const [viewWidth, setViewWidth] = useState<number | null>(window.innerWidth);

  useEffect(() => {
    const getWidth = () => {
      setViewWidth(window.innerWidth);
    }
    window.addEventListener('resize', getWidth, false);

    return () => {
      window.removeEventListener('resize', getWidth, false);
    }
  }, [])

  const parStyle = {
    marginBottom: '1rem',
    maxWidth: '1100px',
    margin: '0 auto 1rem',
    lineHeight: '1.5'
  }

  return (
    <>
      <div className="header" style={{position: 'relative'}}>
        <img src={(viewWidth && viewWidth > 800) ? header : mobile } alt="Ballagnak a tanárok" />
      </div>
      <div className="block_element" style={{paddingTop: '60px', paddingBottom: '40px', maxWidth: '1400px'}}>
        
        <h1 className="download_title">Kattintson a letölteni kíván tartalomra</h1>
        <a style={{fontSize: "1.75rem"}} className="link" href="/Allasfoglalas_Tanarballagas_nov_8.pdf">Állásfoglalás (Tanárballagás, november 8.)</a>
        <h2 style={{marginTop: "4rem"}}>Az állásfoglaláshoz kapcsolódó-aláírásgyűjtés és a "6. pontra" tett javaslatok kiértékelése</h2>
        <div style={{display: "flex", justifyContent: "space-around"}}>
          <a className="link" style={{fontSize: "1.75rem"}} href="/Tanarballagas_6_pont_kiertekeles.pdf" target={"_blank"} rel={"noreferrer"}>Teljes verzió</a>
          <a className="link" style={{fontSize: "1.75rem"}} href="/Tanarballagas_6_pont_kiertekeles_rovid_osszefoglalas.pdf" target={"_blank"} rel={"noreferrer"}>Rövid összefoglalás</a>
        </div>
      </div>
      <div style={{backgroundColor: "#130A71"}}>
        <div className="block_element" style={{paddingTop: '60px', paddingBottom: '40px', maxWidth: '1400px'}}>
        <Svg />
        </div>
      </div>
      <div className="block_element" style={{paddingTop: '60px', paddingBottom: '40px', maxWidth: '1400px'}}>
        <div style={{marginTop: "2rem"}}>
          <p style={parStyle}>
            2022. november elején szerveztük meg a Tanárballagást. Lelkiismeretünk szavára hallgatva kötelességünknek éreztük, hogy kulturált keretek
            között, párbeszédre törekedve kihangosítsuk a felekezeti/egyházi intézményekben dolgozó pedagógusok, az intézményekhez kötődő szülők,
            nagyszülők és öregdiákok és az üggyel szimpatizálók hangját.
          </p>
          <p style={parStyle}>
            Ezért kértük meg a résztvevőket és szimpatizánsokat, hogy az ott megfogalmazott állásfoglalás 5 pontja mellé tegyék hozzá a maguk “6. pontját”,
            javaslatot téve az oktatás jobbá tételére.
          </p>
          <p style={parStyle}>
            Köszönjük, hogy olyan sokan támogatták a kezdeményezést!
          </p>
          <p style={parStyle}>
            A beérkezett javaslatokat feldolgoztuk, ennek eredménye egy hosszabb tanulmány, ill. ennek rövidített változata, melyek elérhetőek a honlapunkon. Fogadják nyitottan!
          </p>
          <p style={parStyle}>
            Ígéretünkhöz híven a javaslatokat eljuttatjuk az érintett felekezeti fenntartóknak (több mint harminc helyre) 2023 januárjában.
          </p>
          <p style={parStyle}>
            A tanulmányt beszélgetésindítónak szántuk, és örömünkre szolgált, hogy sokan mutattak erre nyitottságot. Több beszélgetésen vagyunk túl és még
            továbbiak állnak előttünk!
          </p>
          <p style={parStyle}>
            A rövidebb és a hosszabb változat is bárki számára elérhető honlapunkon. Fogadják nyitottan!
          </p>
          <h2>Kövessenek bennünket az úton a <a href="facebook.com/tanarballagas" target={"_blank"} rel="noreferrer">Facebook oldalunkon!</a></h2>
        </div>
      </div>
      <div className="block_frame" style={(viewWidth && viewWidth > 800) ? {background: '#E3E1E2', color: 'var(--blue)'} : {background: '#E3E1E2', color: 'var(--blue)', paddingBottom: '10rem', position: 'relative', marginBottom: '4rem'}}>
        <div className="block_element" style={{maxWidth: '930px'}}>
          <p style={{fontWeight: '500', marginTop: '40px'}}>Szeretettel hívunk minden pedagóguskollégát, szülőt, hozzátartozót, öregdiákot és támogatót, egyházi fenntartású iskolák és óvodák pályaelhagyó és – egyelőre – pályán maradó</p>
          <p className="strong">pedagógusainak ballagására,</p>
          <p style={{fontWeight: '500'}}>ahol a pedagógusokat szülők és öregdiákok búcsúztatják.</p>
        </div>
        {viewWidth && viewWidth <= 450 ?  
          <div style={{display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', width: '100%', position: 'absolute', left: 0, right: 0, top: '75%' }}>
            <img src={left} style={{height: '100%', width: '100%', objectFit: 'contain'}} alt="Ballagási virágok balról" />
            <img src={right} style={{height: '100%', width: '100%', objectFit: 'contain'}} alt="Ballagási virágok jobbról" />
          </div>
          : null
        }
      </div>
      <div className="fb-share-button-position">
        <ShareButton />
      </div>
    </>
  )
}

export default Header;